const birthdate = [
  /[0-3]/,
  /\d/,
  '/',
  /[0-1]/,
  /[0-9]/,
  '/',
  /[1-2]/,
  /\d/,
  /\d/,
  /\d/,
]

const cpfOrCnsMask = number => {
  const cpf = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
  ]

  const cns = [
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]

  if (number.length < 15) return cpf
  return cns
}

const phoneOrMobile = number => [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export default { birthdate, cpfOrCnsMask, phoneOrMobile }
