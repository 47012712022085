import { useHistory } from 'react-router-dom'
import AmpStoriesOutlined from '@material-ui/icons/AmpStoriesOutlined'
import React, { useEffect, useState } from 'react'

import { constants } from '../../../../utils'
import {
  EmptyPlaceholder,
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../../../components/Placeholders'
import { schedulesService } from '../../../../services'
import { StepTitle } from '../../../../components'
import Table from '../../../../components/Table'

export default ({ setOccupation }) => {
  const history = useHistory()

  const tableConfig = [{ name: 'Ocupação' }]

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [occupations, setOccupations] = useState([])

  const tryToGetOccupations = async () => {
    setLoading(true)
    const { success, payload, error } = await schedulesService.getOccupations()

    if (success) {
      setOccupations(payload.occupations)
    }

    setError(error)
    setLoading(false)
  }

  useEffect(() => {
    tryToGetOccupations()
  }, [])

  return (
    <>
      <StepTitle>Selecione o atendimento que você precisa</StepTitle>
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetOccupations} />
      ) : occupations.length ? (
        <Table
          header={false}
          config={tableConfig}
          onClick={setOccupation}
          rows={occupations}
        />
      ) : (
        <EmptyPlaceholder
          icon={<AmpStoriesOutlined />}
          text={
            constants.strings.placeholders.schedules.create.chooseOccupation
              .empty.text
          }
          buttonText="Voltar"
          onClick={() => history.push(constants.routes.home)}
        />
      )}
    </>
  )
}
