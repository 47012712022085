import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const show = async (uuid) =>
  fetch(url(constants.api.call.show(uuid)), {
    method: 'GET',
    headers: headers(),
  })

export default { show }
