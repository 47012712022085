import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import VideoCall from '@material-ui/icons/VideoCall'

import { tables } from '../../utils'

const useStyles = makeStyles(() => ({
  touchable: {
    cursor: 'pointer',
    '& td': {
      position: 'relative',
    },
  },
  videoCallButton: {
    position: 'absolute',
    top: 10,
    right: 16,
    bottom: 10,
  },
  videoCallIcon: {
    height: 20,
    marginLeft: -5,
    marginRight: 5,
  },
  videoCallCell: {
    paddingRight: 225,
  },
}))

const isVideoCallProcedure = procedure => procedure === '0301010064'

export default ({ onClick, scope, rows = [] }) => (
  <TableBody>
    {rows.map((row, rowId) => (
      <TableRow
        className={clsx(!!onClick && useStyles().touchable)}
        key={rowId}
        onClick={onClick ? () => onClick(row) : null}
      >
        {scope.map((column, colId) => (
          <TableCell
            key={colId + column}
            className={
              isVideoCallProcedure(row['procedure_code']) &&
              column === 'procedure_name'
                ? useStyles().videoCallCell
                : ''
            }
          >
            {tables.getDeepAttribute(row, column)}

            {column === 'procedure_name' &&
              isVideoCallProcedure(row['procedure_code']) && (
                <Button
                  className={useStyles().videoCallButton}
                  variant="outlined"
                  color="primary"
                  onClick={onClick}
                  startIcon={<VideoCall />}
                >
                  Videoconferência
                </Button>
              )}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
)
