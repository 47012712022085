import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const signIn = async ({ document, birthdate, instanceName }) => {
  const body = JSON.stringify({
    document,
    birthdate,
    instance_name: instanceName,
  })

  return fetch(url(constants.api.auth.signIn), {
    method: 'POST',
    headers: headers(false),
    body,
  })
}

export default { signIn }
