import { useHistory } from 'react-router-dom'
import CalendarToday from '@material-ui/icons/CalendarToday'
import React, { useState, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { constants } from '../../utils'
import {
  EmptyPlaceholder,
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../components/Placeholders'
import { PageHeader } from '../../components/Layout'
import { schedulesService } from '../../services'
import Table from '../../components/Table'

export default () => {
  const history = useHistory()
  const mediaQuery = useMediaQuery('(min-width:600px)')

  const tableConfig = () => {
    const base = [{ at: 'Data' }, { procedure_name: 'Procedimento' }]
    if (mediaQuery) base.splice(1, 0, { health_unit_name: 'Local' })
    return base
  }

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [schedules, setSchedules] = useState([])

  const tryToGetSchedules = async () => {
    setLoading(true)
    const { success, payload } = await schedulesService.index()

    if (success) {
      setSchedules(payload.schedules)
      setError(false)
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetSchedules()
  }, [])

  const goToDetails = ({ id }) =>
    history.push(constants.routes.schedules.details.path(id))

  return (
    <>
      <PageHeader
        title={constants.routes.schedules.list.title}
        buttonText="Criar novo"
        onClick={() => history.push(constants.routes.schedules.create.path)}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetSchedules} />
      ) : schedules.length ? (
        <Table config={tableConfig()} rows={schedules} onClick={goToDetails} />
      ) : (
        <EmptyPlaceholder
          icon={<CalendarToday />}
          text={constants.strings.placeholders.schedules.empty.text}
        />
      )}
    </>
  )
}
