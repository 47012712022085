import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState, useEffect } from 'react'

import { accountService } from '../../services'
import { constants, validators } from '../../utils'
import {
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../components/Placeholders'
import { FormControl } from '../../components/Forms'
import { PageHeader } from '../../components/Layout'
import { PhoneOrMobileInput } from '../../components/Forms/Inputs'
import { LoadingButton, Subtitle, Snackbar } from '../../components'

const { phoneIsValid } = validators

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  confirmButton: {},
}))

export default () => {
  const classes = useStyles()
  const history = useHistory()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [updateSnackInfo, setSnackInfo] = useState(accountService.openMessage())

  const [account, setAccount] = useState([])

  const handleForm = e => {
    e.preventDefault()
    tryToUpdateAccount()
  }

  const handleCloseUpdateSnackInfo = (_, reason) =>
    reason !== 'clickaway' && setSnackInfo('')

  const tryToGetAccountInfo = async () => {
    setLoading(true)
    const { success, payload } = await accountService.getAccountInfo()

    if (success) {
      setAccount(payload.account)
      setError(false)
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetAccountInfo()
  }, [])

  const tryToUpdateAccount = async () => {
    setLoadingUpdate(true)

    const { success, message, error, redirect } =
      await accountService.updateAccount(account)

    if (success) {
      setSnackInfo(message)

      if (redirect) setTimeout(() => history.push(constants.routes.home), 0)
    } else {
      setSnackInfo(error)
    }

    setLoadingUpdate(false)
  }

  const hasAtLeastOnePhoneNumber = () =>
    !!(account.celphone || account.residential_phone || account.comercial_phone)

  const shouldEnableUpdateButton = () =>
    hasAtLeastOnePhoneNumber() &&
    phoneIsValid(account.celphone) &&
    phoneIsValid(account.residential_phone) &&
    phoneIsValid(account.comercial_phone)

  return (
    <>
      <PageHeader title={constants.routes.myAccount.edit.title} />
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetAccountInfo} />
      ) : (
        <>
          <Subtitle>Dados de contato</Subtitle>
          <form onSubmit={handleForm}>
            <Grid container>
              <Grid item md={4} xs={12}>
                <FormControl>
                  <InputLabel htmlFor="celphone">Celular</InputLabel>
                  <Input
                    name="celphone"
                    error={!phoneIsValid(account.celphone)}
                    value={account.celphone}
                    onChange={e =>
                      setAccount({ ...account, celphone: e.target.value })
                    }
                    inputComponent={PhoneOrMobileInput}
                    disabled={loadingUpdate}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl>
                  <InputLabel htmlFor="residential_phone">
                    Telefone residencial
                  </InputLabel>
                  <Input
                    name="residential_phone"
                    error={!phoneIsValid(account.residential_phone)}
                    value={account.residential_phone}
                    onChange={e =>
                      setAccount({
                        ...account,
                        residential_phone: e.target.value,
                      })
                    }
                    inputComponent={PhoneOrMobileInput}
                    disabled={loadingUpdate}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl>
                  <InputLabel htmlFor="comercial_phone">
                    Telefone comercial
                  </InputLabel>
                  <Input
                    name="comercial_phone"
                    error={!phoneIsValid(account.comercial_phone)}
                    value={account.comercial_phone}
                    onChange={e =>
                      setAccount({
                        ...account,
                        comercial_phone: e.target.value,
                      })
                    }
                    inputComponent={PhoneOrMobileInput}
                    disabled={loadingUpdate}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <LoadingButton
                className={classes.confirmButton}
                disabled={!shouldEnableUpdateButton()}
                loading={loadingUpdate}
                type="submit"
              >
                Atualizar
              </LoadingButton>
            </div>
          </form>

          <Snackbar
            open={updateSnackInfo}
            message={updateSnackInfo}
            onClose={handleCloseUpdateSnackInfo}
          />
        </>
      )}
    </>
  )
}
