import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import { BoldLabel, Caption, Label } from '../../'

const useStyles = makeStyles(() => ({
  infoContainer: {
    marginLeft: '8px',
  },
}))

const Procedure = ({ procedure }) => {
  const { name = '-', occupation, instructions } = procedure || {}

  return (
    <>
      <Label>{`- ${name}`}</Label>

      <div className={useStyles().infoContainer}>
        {occupation && <Caption>{`Especialidade: ${occupation}`}</Caption>}
        {instructions && <Caption>{`Instruções: ${instructions}`}</Caption>}
      </div>
    </>
  )
}

export default ({ procedures = [] }) => {
  const hasMoreThanOneProcedure = procedures.length > 1

  return (
    <>
      <BoldLabel>
        {hasMoreThanOneProcedure ? 'Procedimentos' : 'Procedimento'}
      </BoldLabel>

      {hasMoreThanOneProcedure ? (
        procedures.map(procedure => <Procedure procedure={procedure} />)
      ) : (
        <Procedure procedure={procedures[0]} />
      )}
    </>
  )
}
