import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const getAvailableAgendas = async ({
  healthUnitId,
  occupationCode,
  period,
  procedureId,
}) =>
  fetch(
    url(
      constants.api.schedules.create.availableAgendas({
        healthUnitId,
        occupationCode,
        period,
        procedureId,
      })
    ),
    {
      method: 'GET',
      headers: headers(),
    }
  )

const getHealthUnits = async ({ occupationCode, procedureId }) =>
  fetch(
    url(
      constants.api.schedules.create.healthUnits({
        occupationCode,
        procedureId,
      })
    ),
    {
      method: 'GET',
      headers: headers(),
    }
  )

const getOccupations = async () =>
  fetch(url(constants.api.schedules.create.occupations), {
    method: 'GET',
    headers: headers(),
  })

const getPeriods = async ({ healthUnitId, occupationCode, procedureId }) =>
  fetch(
    url(
      constants.api.schedules.create.periods({
        healthUnitId,
        occupationCode,
        procedureId,
      })
    ),
    {
      method: 'GET',
      headers: headers(),
    }
  )

const index = async () =>
  fetch(url(constants.api.schedules.index), {
    method: 'GET',
    headers: headers(),
  })

const show = async id =>
  fetch(url(constants.api.schedules.show(id)), {
    method: 'GET',
    headers: headers(),
  })

const create = async scheduleId => {
  const body = JSON.stringify({
    schedule_id: scheduleId,
  })

  return fetch(url(constants.api.schedules.create.create), {
    method: 'POST',
    headers: headers(),
    body,
  })
}

export default {
  getAvailableAgendas,
  getHealthUnits,
  getOccupations,
  getPeriods,
  index,
  show,
  create,
}
