import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Paper from '@material-ui/core/Paper'

import { extraInfo } from '../../utils'

const useStyles = makeStyles(() => ({
  box: {
    padding: 15,
    marginTop: -14,
    marginBottom: 28,
  },
  dangerStyle: {
    '& h3': {
      margin: 0,
      marginBottom: 7,
    },
    '& p': {
      margin: 0,
    },
    '& ul': {
      margin: '10px 0',
    },
  },
}))

export default ({ occupation }) => {
  const classes = useStyles()

  const { procedure_id: procedure } = occupation
  const info = extraInfo.getProcedureInfo(procedure)

  if (info) {
    return (
      <Paper className={classes.box} variant="outlined">
        <div
          className={classes.dangerStyle}
          dangerouslySetInnerHTML={{ __html: info }}
        />
      </Paper>
    )
  } else {
    return null
  }
}
