import constants from './constants'
import urlParams from './urlParams'

const getProcedureInfo = procedure => {
  const { value: cityName } = urlParams.getCity()

  try {
    const { procedures, messages } = constants.strings.extraInfo[cityName]
    return messages[procedures[procedure]]
  } catch (e) {}

  return ''
}

export default {
  getProcedureInfo,
}
