import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '20px 0',
  },
}))

export default ({ ...rest }) => (
  <Divider className={useStyles().divider} {...rest} />
)
