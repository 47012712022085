import { arrays, authUtils, constants, dates } from '../utils'
import { schedulesApi } from '../api'

const { clearAuthAndRedirect } = authUtils

const getAvailableAgendas = async ({
  healthUnit,
  occupation,
  period = '--',
}) => {
  try {
    const { id: healthUnitId = '--' } = healthUnit || {}

    const {
      occupation_code: occupationCode = '--',
      procedure_id: procedureId = '--',
    } = occupation || {}

    const response = await schedulesApi.getAvailableAgendas({
      healthUnitId,
      occupationCode,
      period,
      procedureId,
    })

    const { ok, status } = response

    if (ok && status === 200) {
      const items = await response.json()

      return {
        success: true,
        payload: {
          availableAgendas: arrays.handleListDates({
            format: 'llll',
            items,
          }),
        },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const getHealthUnits = async occupation => {
  try {
    const {
      occupation_code: occupationCode = '--',
      procedure_id: procedureId = '--',
    } = occupation || {}

    const response = await schedulesApi.getHealthUnits({
      occupationCode,
      procedureId,
    })

    const { ok, status } = response

    if (ok && status === 200) {
      const healthUnits = await response.json()

      return {
        success: true,
        payload: { healthUnits },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const getOccupations = async () => {
  try {
    const response = await schedulesApi.getOccupations()
    const { ok, status } = response

    if (ok && status === 200) {
      const occupations = await response.json()

      return {
        success: true,
        payload: { occupations },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const getPeriods = async ({ healthUnit, occupation }) => {
  try {
    const { id: healthUnitId = '--' } = healthUnit || {}

    const {
      occupation_code: occupationCode = '--',
      procedure_id: procedureId = '--',
    } = occupation || {}

    const response = await schedulesApi.getPeriods({
      healthUnitId,
      occupationCode,
      procedureId,
    })

    const { ok, status } = response

    if (ok && status === 200) {
      const periods = await response.json()

      return {
        success: true,
        payload: {
          periods,
        },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const index = async () => {
  try {
    const response = await schedulesApi.index()
    const { ok, status } = response

    if (ok && status === 200) {
      const items = await response.json()

      return {
        success: true,
        payload: {
          schedules: arrays.handleListDates({
            format: 'DD/MM/YY [às] LT',
            items,
          }),
        },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const show = async (id = '--') => {
  try {
    const response = await schedulesApi.show(id)
    const { ok, status } = response

    if (ok && status === 200) {
      const schedule = await response.json()

      return {
        success: true,
        payload: {
          schedule: {
            ...schedule,
            at: dates.format(schedule.at, 'LLLL'),
          },
        },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const create = async agenda => {
  try {
    const { id = '--' } = agenda || {}
    const response = await schedulesApi.create(id)

    const { ok, status } = response

    if (ok && status === 200) {
      const confirmed = await response.json()

      return {
        success: true,
        payload: {
          confirmed: {
            ...confirmed,
            at: dates.format(confirmed.at, 'LLLL'),
          },
        },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      case 409:
        const { error: serverMessage } = await response.json()

        return {
          error:
            serverMessage ||
            constants.strings.messages.errors.schedules.create.confirmation
              .duplicatedRequest,
        }
      case 410:
        return {
          error:
            constants.strings.messages.errors.schedules.create.confirmation
              .unavailableAgenda,
        }
      case 422:
        return {
          error:
            constants.strings.messages.errors.schedules.create.confirmation
              .unprocessableEntity,
        }
      default:
    }
  } catch (e) {}

  return {
    error:
      constants.strings.messages.errors.schedules.create.confirmation.generic,
  }
}

export default {
  getAvailableAgendas,
  getHealthUnits,
  getOccupations,
  getPeriods,
  index,
  show,
  create,
}
