import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'

export default ({ message, onClose, open = '', ...rest }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={9000}
    key="snackbar-top-center"
    message={message}
    onClose={onClose}
    open={!!open}
    {...rest}
  />
)
