import { useHistory } from 'react-router-dom'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import Event from '@material-ui/icons/Event'
import ExitToApp from '@material-ui/icons/ExitToApp'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'

import { authUtils, constants } from '../../../utils'

export default ({ onClick }) => {
  const history = useHistory()

  return (
    <>
      <List>
        <ListItem
          button
          key={constants.routes.schedules.list.key}
          onClick={() => {
            onClick()
            history.push(constants.routes.schedules.list.path)
          }}
        >
          <ListItemIcon>
            <Event />
          </ListItemIcon>
          <ListItemText primary={constants.routes.schedules.list.title} />
        </ListItem>

          <ListItem
            button
            key={constants.routes.myAccount.edit.key}
            onClick={() => {
              onClick()
              history.push(constants.routes.myAccount.edit.path)
            }}
          >
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={constants.routes.myAccount.edit.title} />
          </ListItem>

        <ListItem
          button
          key={constants.routes.signout.key}
          onClick={authUtils.clearAuthAndRedirect}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={constants.routes.signout.title} />
        </ListItem>
      </List>
    </>
  )
}
