import { useHistory } from 'react-router-dom'
import AmpStoriesOutlined from '@material-ui/icons/AmpStoriesOutlined'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'

import { constants } from '../../../../utils'
import {
  EmptyPlaceholder,
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../../../components/Placeholders'
import { schedulesService } from '../../../../services'
import { StepTitle } from '../../../../components'
import Table from '../../../../components/Table'

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '28px',
  },
}))

export default ({ back, occupation, setHealthUnit }) => {
  const classes = useStyles()
  const history = useHistory()

  const tableConfig = [{ name: 'Nome' }, { address: 'Endereço' }]

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [healthUnits, setHealthUnits] = useState([])

  const tryToGetHealthUnits = async () => {
    setLoading(true)

    const { success, payload, error } = await schedulesService.getHealthUnits(
      occupation
    )

    if (success) {
      setHealthUnits(payload.healthUnits)
    }

    setError(error)
    setLoading(false)
  }

  useEffect(() => {
    tryToGetHealthUnits()
  }, [])

  return (
    <>
      <StepTitle>Qual melhor local para você ser atendido?</StepTitle>
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetHealthUnits} />
      ) : healthUnits.length ? (
        <>
          <Table
            header={false}
            config={tableConfig}
            onClick={setHealthUnit}
            rows={healthUnits}
          />
          <div className={classes.buttons}>
            <Button disabled={loading} variant="contained" onClick={back}>
              Mudar especialidade
            </Button>
          </div>
        </>
      ) : (
        <EmptyPlaceholder
          icon={<AmpStoriesOutlined />}
          text={
            constants.strings.placeholders.schedules.create.chooseHealthUnit
              .empty.text
          }
          buttonText="Voltar"
          onClick={() => history.push(constants.routes.home)}
        />
      )}
    </>
  )
}
