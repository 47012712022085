import Chip from '@material-ui/core/Chip'
import Event from '@material-ui/icons/Event'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'

const useStyles = makeStyles(() => ({
  root: {
    margin: '12px 0',
  },
  chip: {
    margin: '0 8px 8px 0',
  },
}))

export default ({ items = [], firstActive }) => {
  const classes = useStyles()

  const [active, setActive] = useState(firstActive || 0)

  const isActive = index => index === active

  const clickItem = (index, func) => () => {
    setActive(index)
    func()
  }

  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        const { label, onClick } = item

        return (
          <Chip
            className={classes.chip}
            icon={<Event />}
            key={index + label}
            label={label}
            onClick={clickItem(index, onClick)}
            color={isActive(index) ? 'secondary' : 'default'}
          />
        )
      })}
    </div>
  )
}
