import React from 'react'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

import { constants } from '../../utils'
import BasePlaceholder from './BasePlaceholder'

export default ({ onClick, ...rest }) => (
  <BasePlaceholder
    text={constants.strings.placeholders.error.text}
    icon={<ErrorOutline />}
    buttonText={constants.strings.placeholders.error.button}
    onClick={onClick}
    {...rest}
  />
)
