import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import { BoldLabel, Label } from '../'

const useStyles = makeStyles(() => ({
  root: {
    margin: '28px 0',
    minWidth: '80%',
  },
  info: {
    marginBottom: '16px',
  },
}))

export default ({ occupation, healthUnit, agenda }) => {
  const classes = useStyles()

  const { name: occupationName = '--' } = occupation || {}
  const { at = '--' } = agenda || {}

  const { name: healthUnitName = '--', address: healthUnitAddress = '--' } =
    healthUnit || {}

  return (
    <section className={classes.root}>
      <div className={classes.info}>
        <BoldLabel>Data e horário do agendamento</BoldLabel>
        <Label>{at}</Label>
      </div>
      <div className={classes.info}>
        <BoldLabel>Local do atendimento</BoldLabel>
        <Label>{healthUnitName}</Label>
        <Label>{healthUnitAddress}</Label>
      </div>
      <div className={classes.info}>
        <BoldLabel>Especialidade e procedimento</BoldLabel>
        <Label>{occupationName}</Label>
      </div>
    </section>
  )
}
