const documentIsValid = number => {
  return number.length === 14 || number.length === 18
}

const birthdateIsValid = date => {
  return /([0-9]{2}\/[0-9]{2}\/[0-9]{4})/.test(date)
}

const isPresent = data => {
  return !!data
}

const phoneIsValid = number => {
  return (
    !number || number.length === 0 || /\([1-9]{2}\)\s[0-9]{8,9}$/.test(number)
  )
}

export default { documentIsValid, birthdateIsValid, isPresent, phoneIsValid }
