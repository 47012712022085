import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ChooseAgenda,
  ChooseHealthUnit,
  ChooseOccupation,
  Confirmation,
  Finished,
} from './Steps'
import { arrays, constants } from '../../../utils'
import { PageHeader } from '../../../components/Layout'
import { Progress } from '../../../components'

export default () => {
  const history = useHistory()

  const [occupation, setOccupation] = useState('')
  const [healthUnit, setHealthUnit] = useState('')
  const [agenda, setAgenda] = useState('')
  const [confirmed, setConfirmed] = useState(null)

  const currentStep = () =>
    arrays.countTrue([occupation, healthUnit, agenda, confirmed])

  const renderCurrentStep = () => {
    switch (currentStep()) {
      case 0:
        return <ChooseOccupation setOccupation={setOccupation} />
      case 1:
        return (
          <ChooseHealthUnit
            back={() => setOccupation('')}
            occupation={occupation}
            setHealthUnit={setHealthUnit}
          />
        )
      case 2:
        return (
          <ChooseAgenda
            back={() => setHealthUnit('')}
            healthUnit={healthUnit}
            occupation={occupation}
            setAgenda={setAgenda}
          />
        )
      case 3:
        return (
          <Confirmation
            agenda={agenda}
            back={() => setAgenda('')}
            healthUnit={healthUnit}
            occupation={occupation}
            setConfirmed={setConfirmed}
          />
        )
      default:
        return <Finished confirmed={confirmed} />
    }
  }

  return (
    <>
      <PageHeader
        extraClasses="no-print"
        title={constants.routes.schedules.create.title}
        buttonText={confirmed ? null : 'Cancelar'}
        onClick={
          confirmed
            ? null
            : () => history.push(constants.routes.schedules.list.path)
        }
      />
      <Progress value={currentStep()} />
      {renderCurrentStep()}
    </>
  )
}
