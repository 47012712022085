import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  typo: {
    margin: '4px 0',
  },
}))

export default ({ children, ...rest }) => (
  <Typography className={useStyles().typo} variant="body2" {...rest}>
    {children}
  </Typography>
)
