import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const show = async () =>
  fetch(url(constants.api.myAccount), {
    method: 'GET',
    headers: headers(),
  })

const update = async account =>
  fetch(url(constants.api.myAccount), {
    method: 'PATCH',
    headers: headers(),
    body: JSON.stringify(account),
  })

export default { show, update }
