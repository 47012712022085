import { Redirect } from 'react-router-dom'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import { authService } from '../../services'
import { authUtils, constants, validators } from '../../utils'
import { BirthdateInput, CpfOrCnsInput } from '../../components/Forms/Inputs'
import { FormControl } from '../../components/Forms'
import { LoadingButton, Snackbar } from '../../components'
import logo from '../../assets/logo.png'

const { documentIsValid, birthdateIsValid } = validators

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '80%',
    maxWidth: '340px',
    padding: 20,
  },
  logo: {
    height: '115px',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    margin: '24px 0 16px',
  },
  sendButton: {
    marginTop: '16px',
  },
}))

export default () => {
  if (authUtils.isAuth()) return <Redirect to={constants.routes.home} />

  const classes = useStyles()

  const [birthdate, setBirthdate] = useState('')
  const [canSignIn, setCanSignIn] = useState(false)
  const [document, setDocument] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleLogin = e => {
    e.preventDefault()
    tryToSignIn()
  }

  const handleCloseError = (_, reason) =>
    reason !== 'clickaway' && setError(false)

  const tryToSignIn = async () => {
    if (!canSignIn) return

    setLoading(true)

    const { success, payload } = await authService.signIn({
      document,
      birthdate,
    })

    if (success) {
      authUtils.writeAuth(payload)
      window.location.reload()
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    setCanSignIn(documentIsValid(document) && birthdateIsValid(birthdate))
  }, [document, birthdate])

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.box}>
          <img className={classes.logo} src={logo} alt="Logo souCidadão" />
          <Typography className={classes.title} variant="h6">
            Entrar
          </Typography>
          <form onSubmit={handleLogin}>
            <FormControl>
              <InputLabel htmlFor="document">CPF/CNS</InputLabel>
              <Input
                name="document"
                value={document}
                onChange={e => setDocument(e.target.value)}
                inputComponent={CpfOrCnsInput}
                disabled={loading}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="birthdate">Data de nascimento</InputLabel>
              <Input
                name="birthdate"
                value={birthdate}
                onChange={e => setBirthdate(e.target.value)}
                inputComponent={BirthdateInput}
                disabled={loading}
              />
            </FormControl>
            <FormControl>
              <LoadingButton
                color="primary"
                disabled={!canSignIn || loading}
                loading={loading}
                type="submit"
                variant="contained"
                className={classes.sendButton}
              >
                Entrar
              </LoadingButton>
            </FormControl>
          </form>
        </Paper>
      </div>
      <Snackbar
        open={error}
        message={constants.strings.messages.errors.auth.unauthorized}
        onClose={handleCloseError}
      />
    </>
  )
}
