import constants from './constants'
import storage from './storage'

const { _session } = constants.params.keys.authUtils

const isAuth = () => !!storage.getItem(_session)

const readAuth = () => JSON.parse(storage.getItem(_session))

const writeAuth = auth => storage.setItem(_session, JSON.stringify(auth))

const clearAuthAndRedirect = () => {
  storage.removeItem(_session)
  window.location.reload()
}

const getAuthToken = () => {
  try {
    return readAuth()['token']
  } catch (e) {}

  return ''
}

const getUserName = () => {
  try {
    return readAuth()['user']['name']
  } catch (e) {}

  return ''
}

const userMustUpdateContact = () => {
  try {
    return readAuth()['user']['must_update_contact']
  } catch (e) {}

  return false
}

const contactUpdated = () => {
  try {
    const auth = readAuth()

    writeAuth({
      ...auth,
      user: { ...auth.user, must_update_contact: false },
    })
  } catch (e) {}

  return
}

export default {
  clearAuthAndRedirect,
  contactUpdated,
  isAuth,
  getAuthToken,
  getUserName,
  userMustUpdateContact,
  writeAuth,
}
