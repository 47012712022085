import { authApi } from '../api'
import { dates, strings, urlParams } from '../utils'

const signIn = async ({ document, birthdate }) => {
  try {
    const response = await authApi.signIn({
      document: strings.removeBlankSpaces(document),
      birthdate: dates.format(birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
      instanceName: urlParams.getCity()['value'],
    })

    const { ok, status } = response

    if (ok && status === 200) {
      const { user, token } = await response.json()

      return {
        success: true,
        payload: { user, token },
      }
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

export default { signIn }
