import Paper from '@material-ui/core/Paper'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'

import { tables } from '../../utils'
import Body from './Body'
import Head from './Head'

export default ({ config, header = true, onClick, rows }) => {
  const columns = tables.getColumnNames(config)
  const rowScope = tables.getRowScope(config)

  return (
    <TableContainer component={Paper}>
      <Table>
        {header && <Head columns={columns} />}
        <Body scope={rowScope} onClick={onClick} rows={rows} />
      </Table>
    </TableContainer>
  )
}
