import { authUtils, constants } from '../utils'
import { accountApi } from '../api'

const { clearAuthAndRedirect, userMustUpdateContact } = authUtils

const getAccountInfo = async () => {
  try {
    const response = await accountApi.show()
    const { ok, status } = response

    if (ok && status === 200) {
      const account = await response.json()

      return {
        success: true,
        payload: { account },
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      default:
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

const updateAccount = async account => {
  try {
    const response = await accountApi.update(account)
    const { ok, status } = response

    if (ok && status === 200) {
      const redirect = userMustUpdateContact()

      authUtils.contactUpdated()

      return {
        success: true,
        message: constants.strings.messages.success.account.update.generic,
        redirect,
      }
    }

    switch (status) {
      case 401:
        clearAuthAndRedirect()
        break
      case 422:
        return {
          error:
            constants.strings.messages.errors.account.update
              .unprocessableEntity,
        }
      default:
    }
  } catch (e) {}

  return {
    error: constants.strings.messages.errors.account.update.generic,
  }
}

const openMessage = () => {
  switch (true) {
    case userMustUpdateContact():
      return constants.strings.messages.info.account.edit.mustUpdateContact
    default:
      return ''
  }
}

export default {
  getAccountInfo,
  updateAccount,
  openMessage,
}
