import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { colors, componentUtils } from '../../utils'

const { wrappedComponent } = componentUtils

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '400px',
    minHeight: '220px',
  },
  icon: {
    fontSize: '100px',
    color: `${colors.theme.palette.grey['600']} !important`,
  },
  text: {
    margin: '20px 0',
    maxWidth: '320px',
    textAlign: 'center',
  },
}))

export default ({ icon, text, buttonText, onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {wrappedComponent(icon)({ className: classes.icon })}
      <Typography className={classes.text} variant="body1">
        {text}
      </Typography>
      {onClick && (
        <Button variant="contained" color="default" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
