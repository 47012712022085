import { authUtils, constants } from '../utils'

const headers = (useToken = true) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  }

  if (useToken) headers['Authorization'] = authUtils.getAuthToken()
  return headers
}

const url = endpoint =>
  constants.api.baseUrl + constants.api.currentVersion + endpoint

export default { headers, url }
