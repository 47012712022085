import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { BoldLabel, Label } from '../'
import { dates, urlParams } from '../../utils'

const useStyles = makeStyles(() => ({
  confirmation: {
    textAlign: 'center',
  },

  subtitle: {
    marginTop: '12px',
  },
}))

export default () => {
  const classes = useStyles()
  const hallName = urlParams.getCity()['label']

  return (
    <section className={classes.confirmation}>
      <BoldLabel>{`PREFEITURA MUNICIPAL${
        hallName ? ' DE ' + hallName.toUpperCase() : ''
      }`}</BoldLabel>

      <Label>{dates.now('LLL')}</Label>

      <Typography variant="h6" className={classes.subtitle}>
        Comprovante de Agendamento
      </Typography>
    </section>
  )
}
