import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

import { constants } from '../../utils'
import BasePlaceholder from './BasePlaceholder'

export default ({ ...rest }) => (
  <BasePlaceholder
    icon={<CircularProgress size={30} />}
    text={constants.strings.placeholders.loading.text}
    {...rest}
  />
)
