import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  formControl: {
    width: 'calc(100% - 12px)',
    margin: '8px 0',
  },
}))

export default ({ ...rest }) => (
  <FormControl className={useStyles().formControl} {...rest} />
)
