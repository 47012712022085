import React from 'react'
import Typography from '@material-ui/core/Typography'

import { BoldLabel, Divider, Label } from '../../'

export default ({ healthUnit }) => {
  const { name = '--', addresses = [] } = healthUnit || {}

  const hasMoreThanOneAddress = addresses.length > 1

  return (
    <section>
      <Divider />
      <Typography variant="subtitle1">Local de atendimento</Typography>
      <BoldLabel>Unidade</BoldLabel>
      <Label>{name}</Label>
      <BoldLabel>{hasMoreThanOneAddress ? 'Endereços' : 'Endereço'}</BoldLabel>
      {hasMoreThanOneAddress ? (
        addresses.map(address => <Label>{`- ${address}`}</Label>)
      ) : (
        <Label>{addresses[0] || '--'}</Label>
      )}
    </section>
  )
}
