import createTheme from '@material-ui/core/styles/createTheme'

export default {
  theme: createTheme({
    palette: {
      background: {
        paper: '#fffef9',
        default: '#fffcef',
      },
      primary: {
        light: '#797bff',
        main: '#3a52db',
        dark: '#0027a8',
      },
      secondary: {
        light: '#8590ac',
        main: '#58637d',
        dark: '#2d3951',
      },
      text: {
        primary: '#3a3a3a',
        secondary: '#141414',
        disabled: '#646464',
      },
    },
  }),
}
