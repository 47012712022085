import Button from '@material-ui/core/Button'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'

import { constants } from '../../../../utils'
import {
  EmptyPlaceholder,
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../../../components/Placeholders'
import { schedulesService } from '../../../../services'
import { Chips, StepTitle } from '../../../../components'
import Table from '../../../../components/Table'

const periods = [
  { key: 'today', label: 'Hoje' },
  { key: 'this-week', label: 'Esta semana' },
  { key: 'next-week', label: 'Próxima semana' },
  { key: 'this-month', label: 'Este mês' },
  { key: 'next-month', label: 'Próximo mês' },
  { key: 'future', label: 'Meses futuros' },
]

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '24px',
  },
}))

export default ({ back, healthUnit, occupation, setAgenda }) => {
  const classes = useStyles()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [agendas, setAgendas] = useState([])
  const [filters, setFilters] = useState([])
  const [period, setPeriod] = useState('')

  const tableConfig = [{ at: 'Data' }]

  const tryToGetAvailablePeriods = async () => {
    setLoading(true)

    const { success, payload, error } = await schedulesService.getPeriods({
      healthUnit,
      occupation,
    })

    if (success) {
      const available = periods
        .filter(i => payload.periods.includes(i.key))
        .map(i => ({ ...i, onClick: () => setPeriod(i.key) }))

      const current = (available[0] || {}).key

      setFilters(available)
      setPeriod(current)
    }

    if (error) {
      setError(error)
      setLoading(false)
    }
  }

  const tryToGetAvailableAgendas = async () => {
    setLoading(true)

    const { success, payload, error } =
      await schedulesService.getAvailableAgendas({
        healthUnit,
        occupation,
        period,
      })

    if (success) {
      setAgendas(payload.availableAgendas)
    }

    setError(error)
    setLoading(false)
  }

  useEffect(() => {
    tryToGetAvailablePeriods()
  }, [])

  useEffect(() => {
    if (period) {
      tryToGetAvailableAgendas()
    }
  }, [period])

  return (
    <>
      <StepTitle>Escolha a data (utilize os filtros)</StepTitle>
      <Chips items={filters} />
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetAvailableAgendas} />
      ) : agendas.length ? (
        <>
          <Table
            header={false}
            config={tableConfig}
            onClick={setAgenda}
            rows={agendas}
          />
          <div className={classes.buttons}>
            <Button disabled={loading} variant="contained" onClick={back}>
              Mudar local
            </Button>
          </div>
        </>
      ) : (
        <EmptyPlaceholder
          icon={<DateRangeOutlined />}
          text={
            constants.strings.placeholders.schedules.create.chooseAgenda.empty
              .text
          }
          buttonText="Voltar"
          onClick={back}
        />
      )}
    </>
  )
}
