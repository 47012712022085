import { useHistory, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../components/Layout'

import {
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../components/Placeholders'

import { authUtils, constants } from '../utils'
import { callService } from '../services'

const useStyles = makeStyles(() => ({
  text: {
    margin: '16px 0',
  },
  button: {
    marginTop: 10,
  },
}))

export default () => {
  const classes = useStyles()
  const history = useHistory()

  const { uuid } = useParams()

  const callUrl = constants.params.jitsi.uri(
    uuid,
    authUtils.getUserName().split(' ')[0]
  )

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const startCall = async () => {
      setLoading(true)

      const { error } = await callService.show(uuid)

      if (error) {
        setError(true)
      }

      setLoading(false)
    }

    startCall()
  }, [uuid])

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorPlaceholder />
  }

  return (
    <div className={classes.root}>
      <PageHeader title="Videoconferência" />

      <div
        dangerouslySetInnerHTML={{
          __html: constants.params.jitsi.iframe(callUrl),
        }}
      />

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Encerrar
      </Button>
    </div>
  )
}
