import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  circularProgress: {
    marginRight: 10,
  },
}))

export default ({ children, loading, ...rest }) => (
  <Button variant="contained" color="primary" {...rest}>
    {loading && (
      <CircularProgress className={useStyles().circularProgress} size={20} />
    )}
    {children}
  </Button>
)
