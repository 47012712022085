import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import React from 'react'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

import { constants } from '../utils'

import {
  CreateSchedule,
  ListSchedules,
  PrintSchedule,
  ScheduleDetails,
} from '../screens/Schedules'
import { NotFound, Call } from '../screens'
import { SignIn } from '../screens/Auth'
import { EditAccount } from '../screens/MyAccount'

const { routes } = constants

export default () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute
        exact
        path="/"
        component={() => <Redirect to={routes.signIn.path} />}
      />
      <PrivateRoute
        exact
        path={routes.myAccount.edit.path}
        component={() => <EditAccount />}
      />
      <PrivateRoute
        exact
        path={routes.schedules.list.path}
        component={() => <ListSchedules />}
      />
      <PrivateRoute
        exact
        path={routes.schedules.create.path}
        component={() => <CreateSchedule />}
      />
      <PrivateRoute
        exact
        renderLayout={false}
        path={routes.schedules.print.path()}
        component={() => <PrintSchedule />}
      />
      <PrivateRoute
        exact
        path={routes.schedules.details.path()}
        component={() => <ScheduleDetails />}
      />
      <PrivateRoute
        exact
        path={routes.call.path()}
        component={() => <Call />}
      />
      <PublicRoute
        exact
        path={routes.signIn.path}
        component={() => <SignIn />}
      />
      <PublicRoute exact path="*" component={() => <NotFound />} />
    </Switch>
  </BrowserRouter>
)
