import MaskedInput from 'react-text-mask'
import React from 'react'

import { masks } from '../../../utils'

export default ({ inputRef, ...rest }) => (
  <MaskedInput
    ref={ref => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={masks.birthdate}
    showMask
    guide={false}
    {...rest}
  />
)
