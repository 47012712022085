import { callApi } from '../api'

const show = async uuid => {
  try {
    const response = await callApi.show(uuid)
    const { ok, status } = response

    if (ok && status === 200) {
      return { success: true }
    }
  } catch (e) {}

  return { error: 'UnhandledError' }
}

export default { show }
