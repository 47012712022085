import { useHistory, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState, useEffect } from 'react'

import { constants } from '../../utils'
import {
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../components/Placeholders'
import { PageHeader } from '../../components/Layout'
import { ScheduleDetails } from '../../components/Schedules'
import { schedulesService } from '../../services'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '12px',
  },
}))

export default () => {
  const classes = useStyles()
  const history = useHistory()

  const { scheduleId } = useParams()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [schedule, setSchedule] = useState([])

  const goToPrint = () =>
    window
      .open(constants.routes.schedules.print.path(scheduleId), '_blank')
      .focus()

  const tryToGetSchedule = async () => {
    setLoading(true)
    const { success, payload } = await schedulesService.show(scheduleId)

    if (success) {
      setSchedule(payload.schedule)
      setError(false)
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetSchedule()
  }, [])

  return (
    <>
      <PageHeader
        title={constants.routes.schedules.details.title}
        buttonText="Voltar"
        onClick={() => history.push(constants.routes.schedules.list.path)}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetSchedule} />
      ) : (
        <>
          <ScheduleDetails schedule={schedule} showVideoConference />
          <Button
            className={`${classes.button} no-print`}
            color="default"
            variant="contained"
            onClick={goToPrint}
          >
            Imprimir
          </Button>
        </>
      )}
    </>
  )
}
