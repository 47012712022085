import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { authUtils } from '../utils'
import { constants } from '../utils'
import LayoutBase from '../components/Layout/LayoutBase'

const { isAuth, userMustUpdateContact } = authUtils
const { routes } = constants

export default ({ component: Component, renderLayout = true, ...rest }) => {
  const renderWithLayout = ({ ...props }) => (
    <LayoutBase>
      <Component {...props} />
    </LayoutBase>
  )

  const shouldRedirectToMyAccount = () =>
    userMustUpdateContact() && rest.path !== routes.myAccount.edit.path

  return (
    <Route
      {...rest}
      render={props =>
        isAuth() ? (
          shouldRedirectToMyAccount() ? (
            <Redirect to={routes.myAccount.edit.path} />
          ) : renderLayout ? (
            renderWithLayout({ ...props })
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={routes.signIn.path} />
        )
      }
    />
  )
}
