import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'

import { LoadingButton, Snackbar, StepTitle } from '../../../../components'

import {
  ExtraInfo,
  ScheduleConfirmation,
} from '../../../../components/Schedules'

import { schedulesService } from '../../../../services'

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '16px',
  },
  confirmButton: {
    marginLeft: '12px',
  },
  scheduleData: {
    margin: '16px 0 24px',
  },
}))

export default ({ back, occupation, healthUnit, agenda, setConfirmed }) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleCloseError = (_, reason) => reason !== 'clickaway' && setError('')

  const tryToConfirm = async () => {
    setLoading(true)

    const { success, payload, error } = await schedulesService.create(agenda)

    if (success) {
      setConfirmed(payload.confirmed)
    }

    setError(error)
    setLoading(false)
  }

  return (
    <>
      <StepTitle>Confira se os dados estão corretos</StepTitle>
      <ScheduleConfirmation
        occupation={occupation}
        healthUnit={healthUnit}
        agenda={agenda}
      />
      <ExtraInfo occupation={occupation} />
      <div className={classes.buttons}>
        <Button disabled={loading} variant="contained" onClick={back}>
          Mudar data
        </Button>
        <LoadingButton
          className={classes.confirmButton}
          disabled={loading}
          loading={loading}
          onClick={tryToConfirm}
        >
          Confirmar
        </LoadingButton>
      </div>
      <Snackbar open={error} message={error} onClose={handleCloseError} />
    </>
  )
}
