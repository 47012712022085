import { useParams } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState, useEffect } from 'react'

import {
  ErrorPlaceholder,
  LoadingPlaceholder,
} from '../../components/Placeholders'
import { ConfirmationHeader, ScheduleDetails } from '../../components/Schedules'
import { schedulesService } from '../../services'

const useStyles = makeStyles(() => ({
  root: {
    width: '90%',
    maxWidth: '1200px',
    margin: '24px auto 0',
  },
}))

export default () => {
  const classes = useStyles()

  const { scheduleId } = useParams()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [schedule, setSchedule] = useState([])

  const tryToGetSchedule = async () => {
    setLoading(true)
    const { success, payload } = await schedulesService.show(scheduleId)

    if (success) {
      setSchedule(payload.schedule)
      setError(false)
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetSchedule()
  }, [])

  return (
    <div className={classes.root}>
      {loading ? (
        <LoadingPlaceholder />
      ) : error ? (
        <ErrorPlaceholder onClick={tryToGetSchedule} />
      ) : (
        <>
          <ConfirmationHeader />
          <ScheduleDetails schedule={schedule} />
        </>
      )}
    </div>
  )
}
