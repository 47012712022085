const getCity = () => {
  switch (window.location.host) {
    case 'itabirito.soucidadao.appolus.com.br':
      return { label: 'Itabirito', value: 'ITABIRITO' }
    case 'ibirite.soucidadao.appolus.com.br':
      return { label: 'Ibirité', value: 'IBIRITE' }
    default:
      return { label: 'Apresentação', value: 'DESENVOLVIMENTO' }
  }
}

export default { getCity }
