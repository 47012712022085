export default {
  app: {
    name: 'souCidadão',
  },
  api: {
    baseUrl: 'https://soucidadao-api.appolus.com.br',
    currentVersion: '/api/v1/web',
    auth: {
      signIn: '/auth/sign_in',
    },
    call: {
      show: uuid => `/calls/${uuid}`,
    },
    myAccount: '/account',
    schedules: {
      index: '/schedules',
      show: id => `/schedules/${id}?schedule_type=medical_referral_schedule`,
      create: {
        availableAgendas: ({
          healthUnitId,
          occupationCode,
          period,
          procedureId,
        }) =>
          `/scheduling/schedules/available?health_unit_id=${healthUnitId}&occupation_code=${occupationCode}&procedure_id=${procedureId}&period=${period}`,
        create: '/scheduling/medical_referrals',
        healthUnits: ({ occupationCode, procedureId }) =>
          `/scheduling/health_units/available?occupation_code=${occupationCode}&procedure_id=${procedureId}`,
        occupations: '/scheduling/occupations/available',
        periods: ({ healthUnitId, occupationCode, procedureId }) =>
          `/scheduling/schedules/periods?health_unit_id=${healthUnitId}&occupation_code=${occupationCode}&procedure_id=${procedureId}`,
      },
    },
  },
  params: {
    keys: {
      authUtils: {
        _session: '_scwauth',
      },
    },
    sentry: {
      dsn: 'https://387359436bb04d3d93d837ef1c0a8480@o7645.ingest.sentry.io/5421453',
    },
    jitsi: {
      iframe: callUrl => `
        <iframe
          title="call"
          data-first-load="1"
          allow="camera; microphone;"
          src="${callUrl}"
          id="jitsi-room"
          onload="preventReload()"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          style="height: 500px; width: 100%; border: 0px;" />`,
      uri: (uuid, displayName) =>
        `https://meet.jit.si/${uuid}#config.hideConferenceSubject=true&amp;config.startWithVideoMuted=false&amp;config.disableDeepLinking=true&amp;userInfo.moderator=false&amp;userInfo.displayName=%22${encodeURI(
          displayName
        )}%22&amp;config.prejoinPageEnabled=false&amp;config.fileRecordingsEnabled=false&amp;config.fileRecordingsServiceEnabled=false&amp;config.fileRecordingsServiceSharingEnabled=false&amp;config.disableRecordAudioNotification=true&amp;config.toolbarButtons=[%22raisehand%22, %22camera%22, %22chat%22, %22microphone%22, %22fullscreen%22]&amp;config.remoteVideoMenu.disableKick=true&amp;config.remoteVideoMenu.disableGrantModerator=true&amp;config.disableRemoteMute=true`,
    },
  },
  routes: {
    home: '/agendamentos',
    call: {
      key: 'call',
      title: 'Videoconferência',
      path: uuid => `/calls/${uuid || ':uuid'}`,
    },
    myAccount: {
      edit: {
        key: 'edit-myaccount',
        title: 'Minha conta',
        path: '/minha-conta',
      },
    },
    schedules: {
      list: {
        key: 'list-schedules',
        title: 'Agendamentos',
        path: '/agendamentos',
      },
      create: {
        key: 'create-schedule',
        title: 'Novo agendamento',
        path: '/agendamentos/novo',
      },
      details: {
        key: 'schedule-details',
        title: 'Agendamento',
        path: id => `/agendamentos/${id || ':scheduleId'}`,
      },
      print: {
        key: 'schedule-print',
        title: 'Agendamento',
        path: id => `/agendamentos/${id || ':scheduleId'}/impressao`,
      },
    },
    signIn: {
      key: 'signin',
      path: '/entrar',
    },
    signout: {
      key: 'signout',
      title: 'Sair',
    },
  },
  strings: {
    extraInfo: {
      IBIRITE: {
        procedures: {
          53: 'castration',
          54: 'castration',
          89: 'castration',
          92: 'castration',
        },
        messages: {
          castration: `
            <h3>⚠️ Informações importantes</h3>
            <p>Todos os cidadãos de Ibirité têm direito a vagas, contudo alguns animais não são recomendados para castração sem anestesia inalatória, como animais com mais de 7 anos, acima de 15 kg, braquicefálicos (Shihtzu, Buldogue, Pug, Pequenes), de raças pequenas por exemplo: Poodle, Pincher, Lhasa Apso, Maltês, Yorkshire. Em caso de dúvidas se o mesmo pode ou não ser castrado, trazer ao Centro de Controle de Zoonoses pra avaliação prévia.</p>
            <p>Reforçamos que é essencial o jejum de 8 horas do animal. Favor chegar entre  7 e 8 horas da manhã, o atendimento será por ordem de chegada, não serão atendidos animais trazidos após as 8h.</p>
            <p>Trazer xerox dos documentos: identidade, CPF, cartão SUS e comprovante de endereço. Caso uma terceira pessoa venha acompanhar o animal, deve-se trazer a cópia dos documentos da pessoa que consta no agendamento e da pessoa que trará o animal.</p>
            <p>Trazer também uma coberta ou toalha. Para as fêmeas: trazer uma faixa (atadura de crepom) condizente com o tamanho do animal e observar se a mesma está em período de cio ou lactação, impossibilitando a cirurgia.</p>
            <p>Para evitar aglomerações e por falta de espaço, por gentileza não trazer acompanhantes.</p>
            <p>Indispensável o uso de máscaras.</p>
            <p>Endereço: Rua Professor Vicente de Oliveira Torres, 500 - Bairro Jardim Rosário, Ibirité</p>
            <p>Dúvidas ligar para:  (31) 2010-3802</p>
          `,
        },
      },
    },
    messages: {
      errors: {
        account: {
          update: {
            generic: 'Falha ao tentar atualizar os dados, tente novamente',
            unprocessableEntity:
              'Falha ao tentar atualizar os dados, consulte sua unidade de saúde para mais informações',
          },
        },
        auth: {
          unauthorized:
            'Dados incorretos, verifique as informações e tente novamente',
        },
        schedules: {
          create: {
            confirmation: {
              duplicatedRequest:
                'Você já solicitou um agendamento igual a este, verifique os dados ou consulte sua unidade de saúde para mais informações',
              generic:
                'Falha ao realizar agendamento, tente novamente mais tarde',
              unavailableAgenda:
                'Este horário acabou de ser marcado, por favor, tente outro horário',
              unprocessableEntity:
                'Falha ao realizar agendamento, consulte sua unidade de saúde para mais informações',
            },
          },
        },
      },
      info: {
        account: {
          edit: {
            mustUpdateContact:
              'Você precisa atualizar seus dados de contato para utilizar o portal souCidadão',
          },
        },
      },
      success: {
        account: {
          update: {
            generic: 'Seus dados foram atualizados com sucesso',
          },
        },
      },
    },
    placeholders: {
      error: {
        text: 'Falha ao obter as informações, tente novamente',
        button: 'Tentar novamente',
      },
      loading: {
        text: 'Carregando...',
      },
      notFound: {
        text: 'Desculpe, a página solicitada não pôde ser encontrada...',
        button: 'Ir para página inicial',
      },
      schedules: {
        empty: {
          text: 'Você não possui agendamentos no momento',
          button: 'Agendar agora',
        },
        create: {
          chooseOccupation: {
            empty: {
              text: 'No momento não há especialidades disponíveis para atendimento',
            },
          },
          chooseHealthUnit: {
            empty: {
              text: 'No momento não há locais disponíveis para atendimento',
            },
          },
          chooseAgenda: {
            empty: {
              text: 'No momento não há datas disponíveis para agendamento neste período, tente selecionar outro',
            },
          },
        },
      },
    },
    screens: {
      schedules: {
        create: {
          qtdSteps: 5,
          progress: (current, total) => `Etapa ${current} de ${total}`,
        },
      },
    },
  },
}
