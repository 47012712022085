import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import { constants } from '../utils'

const { progress, qtdSteps } = constants.strings.screens.schedules.create

const useStyles = makeStyles(() => ({
  linearProgress: {
    margin: '8px 0 28px',
  },
}))

export default ({ value = 0 }) => (
  <div className="no-print">
    <Typography>{progress(value + 1, qtdSteps)}</Typography>
    <LinearProgress
      className={useStyles().linearProgress}
      variant="determinate"
      value={((value + 1) / qtdSteps) * 100}
      color="secondary"
    />
  </div>
)
