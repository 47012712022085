import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import { colors, constants } from '../../../../utils'
import {
  ConfirmationHeader,
  ScheduleDetails,
} from '../../../../components/Schedules'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '60px',
    color: colors.theme.palette.grey['600'],
    margin: '0 auto 12px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    width: '100%',
  },
  button: {
    margin: '0 6px',
  },
}))

export default ({ confirmed }) => {
  const classes = useStyles()
  const history = useHistory()

  const { id = '' } = confirmed || {}

  const goToPrint = () =>
    window.open(constants.routes.schedules.print.path(id), '_blank').focus()

  return (
    <div className={classes.root}>
      <CheckCircleOutline className={`${classes.icon} no-print`} />

      <ConfirmationHeader />
      <ScheduleDetails schedule={confirmed} />

      <div className={classes.buttons}>
        <Button
          className={`${classes.button} no-print`}
          color="default"
          variant="contained"
          onClick={goToPrint}
        >
          Imprimir
        </Button>
        <Button
          className={`${classes.button} no-print`}
          color="primary"
          variant="contained"
          onClick={() => history.push(constants.routes.schedules.list.path)}
        >
          Ok
        </Button>
      </div>
    </div>
  )
}
