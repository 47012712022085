import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import VideoCall from '@material-ui/icons/VideoCall'
import { useHistory } from 'react-router-dom'

import { constants } from '../../../utils'

import { BoldLabel, Divider, Label } from '../../'

import HealthUnit from './HealthUnit'
import Procedures from './Procedures'

const useStyles = makeStyles(() => ({
  root: {
    margin: '24px 0',
    minWidth: '80%',
  }
}))

export default ({ schedule, showVideoConference = false }) => {
  const classes = useStyles()
  const history = useHistory()

  const {
    at = '--',
    citizen_name: citizenName = '--',
    health_unit: healthUnit,
    procedures,
    professional,
    call_uuid
  } = schedule || {}

  const { name: professionalName = '--' } = professional || {}

  const renderVideoConferenceBtn = () => {
    if (!showVideoConference && !call_uuid) { return null }

    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => history.push(constants.routes.call.path(call_uuid))}
        startIcon={<VideoCall />}
      >
        Videoconferência
      </Button>
    )
  }

  return (
    <div className={classes.root}>
      {renderVideoConferenceBtn()}

      <section>
        <Divider />
        <Typography variant="subtitle1">Informações gerais</Typography>

        <BoldLabel>Data e horário do atendimento</BoldLabel>
        <Label>{at}</Label>

        <BoldLabel>Paciente</BoldLabel>
        <Label>{citizenName}</Label>
      </section>

      <HealthUnit healthUnit={healthUnit} />

      <section>
        <Divider />
        <Typography variant="subtitle1">Detalhes</Typography>

        <BoldLabel>Profissional</BoldLabel>
        <Label>{professionalName}</Label>

        <Procedures procedures={procedures} />
      </section>
    </div>
  )
}
